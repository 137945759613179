import React, { FunctionComponent } from "react";

import Layout from "../../../common/layout/layout";
import SEO from "../../../common/seo";

const LuigiLambertiniPage: FunctionComponent = () => (
  <Layout backUrl="/about/critiques" backText="Critique">
    <SEO title="Luigi Lambertini" />
    <div className="lg-half-width">
      <p>
        Sulle prime tutto pare esatto, al millimetro, definito e concluso. Nulla
        è lasciato al caso, all’improvvisazione, all’estro di un momento. Ogni
        immagine ha una sua collocazione in quello spazio da cui pare sortire ed
        è una collocazione precisa, addirittura categorica. Il gioco dei colori
        inoltre, questa sorta di variazione scalare con cui spesso Nino
        Desirelli costruisce piani e strutture, ha la freddezza di un calcolo,
        la lucidità di una meditazione che quasi sconcerta per il suo rigore e
        per quel concatenarsi tra tessuto e tassello, concatenazione che non
        lascia alcuna alternativa. Partito da un’ipotesi cromatica – che poi è
        un’ipoptesi fino ad un certo punto – le variazioni seguono una cadenza
        che non lascia spazio ad altre ipotesi, meglio, a differenti soluzioni;
        ma questo è soltanto l’aspetto del problema poiché nel loro insieme le
        proposte di Desirelli conducono a ben altro, ad argomentazioni di altro
        tipo.
      </p>
      <p>
        Le immagini del pittore cremonese causano infatti una moltiplicazione di
        spazi per un loro particolare rimando prospettico. Spesso le forme si
        aprono ed allora pare di essere presi dallo srotolarsi di un ventaglio,
        da un turbinio che richiama il vortice di un’elica; in altri casi
        invece, quando la forma geometrica è più costruita, più definita,
        un’analoga sensazione è dovuta al gioco sfasato sempre di prospettive,
        quasi distorsioni generanti proposte di profondità. Ecco, questo è il
        punto. Esiste un’ambivalenza di fondo, controllata finché si vuole, ma
        pur sempre presente per cui non è opportuno privilegiare la strana
        esattezza di Desirelli attribuendole un valore che va al dilà di quella
        funzione stimolatrice che le è propria. I termini della questione
        insomma sono da individuarsi, per una lettura più profonda, in questo
        sfaldamento e nella continua proposta di ordinato disagio, che
        caratterizzano la sua opera.
      </p>
      <p>
        L’esattezza cui ho accennato più volte, è infatti strumentale e, pur
        essendovi connesso un desiderio di ordine, un’esigenza di questo genere
        anche a livello psicologico, per una sorta di strano gioco, per
        invenzione proiezioni, viene ad ingigantire quello piazzamento che, in
        una visione generale è determinante.
      </p>
      <p>
        L’elemento di cerniera dei due termini, non certo in contrasto, bensì
        uno legato all’altro, è costituito da un equilibro che non può essere
        mutato, un’equilibro dovuto alla circostanza che un fattore completa
        l’altro, e viceversa. Di qui discende una dialettica di elementi i
        quali, se vengono considerati separatamente, per la loro diversità
        risultano addirittura in contrasto. E’ però un tema sul quale non è il
        caso di insistere più del dovuto, anche perché ritornerà in primo piano,
        e secondo una diversa prospettiva, specie qualora si prenda in
        considerazione assieme al risultato dell’opera l’atteggiamento, la
        posizione, perfino il modo di essere del pittore. Giustamente Elda
        Fezzi, che ha dedicato a Desirelli le pagine critiche più approfondite,
        si è domandata se queste sequenze non siano il frutto di un interesse
        fantastico e ludico. Ebbene, la sua è un’indicazione da tenere presente.
        L’dea dell’elica, accennata poco fa, equivale infatti (prescindendo da
        qualsiasi annotazione di tipo biografico, come la passione di Desirelli
        per l’aeromodellismo, che potrebbe portare anche ad un accostamento di
        queste sue geometrie alle leggere costruzioni di centine e ordinate) ad
        una proposta di luce, di movimento, di spazio frantumato e mosso; è un
        suggerimento anche di forza e di mutazione secondo un ordine
        prestabilito, inafferrabile eppur esistente; un quid, in altre parole,
        che si vorrebbe concretizzare e, non intendendo per logica di cose
        operare una trascrizione di carattere scientifico, trasferire su di un
        piano più alto, coinvolgente addirittura. Ne discende, a voler leggere
        tra le righe, una visione anche critica della realtà, una proposta
        all’interno della stessa che non è di evasione bensì di partecipazione
        diretta ad un’analisi che parte da un dato per negarlo, ma anche per
        esaltarlo.
      </p>
      <p>
        L’idea di spazio che si concretizza in forme diverse – non per nulla lo
        spazio in cui avviene il fenomeno ha colori analoghi al fenomeno stesso,
        dal bianco all’azzurro al blu – si tramuta così in movimento, in
        scansione ( perché non ricordare allora sotto un certo punto di vista le
        proposte dei Futuristi e le esperienze optical?) quindi, pur essendo
        definita, proprio per certi stravolgimenti raggiunge un’ambiguità che si
        manifesta e si accresce in un rigore che, se il gioco possiede le
        caratteristiche, della realtà ha tuttavia l’ineluttabile durezza. Si è
        pertanto all’interno di una dimensione che ne crea sempre di nuove con
        continui rimandi, il che equivale ad un concetto di libertà e di
        condizionamento. Se da un canto assistiamo al superamento di un fatto,
        di una desinenza concreta come possono esserlo la forma o l’immagine
        prese di per sé, ecco che subito ci si accorge di essere in una
        dimensione diversa.
      </p>
      <p>
        Il rigore dunque come fantasia e la certezza come presupposto di
        ambiguità; “una culla dello spago”, per dirla con un’immagine cara a
        Malraux, che non ci lascia mai allo stesso punto, ma ripropone sempre
        qualcosa di nuovo mantenendo inalterati ogni presupposto ed ogni
        condizione.
      </p>
      <p>Luigi Lambertini</p>
    </div>
  </Layout>
);

export default LuigiLambertiniPage;
